import "./src/styles/global.css"
export const onClientEntry = () => {
  // 1) Add the BLB script
  const scriptTagger = document.createElement("script");
  scriptTagger.src =
    "https://www.blueletterbible.org/assets-v3/scripts/blbToolTip/BLB_ScriptTagger-min.js";
  scriptTagger.type = "text/javascript";

  scriptTagger.onload = () => {
    const scriptConfig = document.createElement("script");
    scriptConfig.type = "text/javascript";
    scriptConfig.innerHTML = `
      BLB.Tagger.Translation = 'NKJV';
      BLB.Tagger.HyperLinks = 'all'; 
      BLB.Tagger.HideTanslationAbbrev = false;
      BLB.Tagger.TargetNewWindow = true;
      BLB.Tagger.Style = 'par'; 
      BLB.Tagger.NoSearchTagNames = ''; 
      BLB.Tagger.NoSearchClassNames = 'noTag doNotTag';
    `;
    document.head.appendChild(scriptConfig);
  };

  document.head.appendChild(scriptTagger);

};